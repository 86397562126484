<template>
  <div class="navbar-container main-menu-content d-flex">
    <div v-if="title && !isSmall" class="mr-2">
      <p class="font-weight-extrabold mb-0 text-primary h4 truncated-text">
        {{ title }}
      </p>
      <p class="font-medium-1 text-muted mb-0 truncated-text">
        <b-icon icon="grid" font-scale="0.9" class="mr-25" />
        {{ $t('settings.menu.title') }}
      </p>
    </div>
    <horizontal-nav-menu-items 
      :is-backoffice="isBackoffice" 
      :items="appsInMenu" 
    />
  </div>
</template>

<script>
import HorizontalNavMenuItems from './components/horizontal-nav-menu-items/HorizontalNavMenuItems.vue';

export default {
  components: {
    HorizontalNavMenuItems,
  },
  props: {
    appsInMenu: {
      type: Array,
      required: true,
    },
    isBackoffice: Boolean,
    title: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      isSmall: false,
    };
  },
  computed: {
    apps() {
      return this.$store.getters.apps;
    },
  },
  setup() {
    return {
      // navMenuItems,
    };
  },
  async created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
    //TODO: delete
    // await this.$store.dispatch('getItems', {
    //   itemType: 'rooms',
    //   // Workaround to ensure that the main room is located
    //   // TODO: request second page if mainRoom is not found
    //   perPage: 1000,
    //   page: 1,
    //   communitySlug: this.$route.params.communityId,
    //   requestConfig: {
    //     orderByDate: -1,
    //   },
    // });
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.isSmall = window.innerWidth < 1320;
    },
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/horizontal-menu.scss";
</style>
