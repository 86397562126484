<template>
  <div
    :key="key"
    class="horizontal-layout"
    :class="[layoutClasses]"
    :data-col="isNavMenuHidden ? '1-column' : null"
    style="height: inherit"
  >
    <!-- NAVBAR -->
    <b-navbar
      :style="{
        backgroundColor:
          navbarType === 'static' && scrolledTo && skin === 'light'
            ? 'white'
            : null,
        boxShadow:
          (navbarType === 'static' && scrolledTo)||hasOnlyNavBar
            ? 'rgba(0, 0, 0, 0.05) 0px 4px 20px 0px'
            : null,
      }"
      :toggleable="false"
      class="
        header-navbar
        align-items-center
        navbar-brand-center navbar-fixed
      "
      :class="{ 'fixed-top': $store.getters['app/currentBreakPoint'] !== 'xl', 'navbar-shadow':!hasOnlyNavBar }"
    >
      <slot name="navbar" :toggleVerticalMenuActive="toggleVerticalMenuActive">
        <app-navbar-horizontal-layout-brand />
        <app-navbar-horizontal-layout
          :toggle-vertical-menu-active="toggleVerticalMenuActive"
        />
      </slot>
    </b-navbar>
    <!--/ NAVBAR -->

    <div
      v-if="!hasNotMenu && contentLayoutType !== 'vertical'"
      class="horizontal-menu-wrapper"
    >
      <div
        v-if="!isNavMenuHidden && appsInMenu && appsInMenu.length > 1"
        :class="[
          navbarMenuTypeClass,
          'header-navbar navbar-expand-sm navbar navbar-horizontal navbar-light navbar-shadow',
          'menu-border d-xl-block',
        ]"
      >
        <horizontal-nav-menu key="appsin-menu" :apps-in-menu="appsInMenu" />
      </div>
    </div>

    <!-- Backoffice menu -->
    <div v-if="hasBackofficeMenu && isStaff" class="horizontal-menu-wrapper">
      <div
        :class="[
          navbarMenuTypeClass,
          'header-navbar navbar-expand-sm navbar navbar-horizontal navbar-light navbar-shadow',
          'menu-border d-xl-block',
        ]"
      >
        <horizontal-nav-menu
          key="backoffice-menu-staff"
          :is-backoffice="true"
          :apps-in-menu="backofficeItems"
          title="Backoffice"
        />
      </div>
    </div>

    <!-- Member Settings menu -->
    <div v-if="hasMyselfMenu" class="horizontal-menu-wrapper">
      <div
        :class="[
          navbarMenuTypeClass,
          'header-navbar navbar-expand-sm navbar navbar-horizontal navbar-light navbar-shadow',
          'menu-border d-xl-block',
        ]"
      >
        <horizontal-nav-menu
          key="myself-menu"
          :is-backoffice="false"
          title="My account"
          :apps-in-menu="memberSettingsMenuItems"
        />
      </div>
    </div>

    <!-- Vertical Nav Menu Overlay -->
    <div
      class="sidenav-overlay"
      :class="overlayClasses"
      @click="isVerticalMenuActive = false"
    />
    <!-- /Vertical Nav Menu Overlay -->

    <!-- CONTENT -->
    <!-- CONTENT TYPE: Left -->
    <transition :name="routerTransition" mode="out-in">
      <component
        :is="layoutContentRenderer"
        :key="layoutContentRendererKey"
        v-bind="layoutContentRendererProps"
      >
        <template v-for="(index, name) in $scopedSlots" v-slot:[name]="data">
          <slot :name="name" v-bind="data" />
        </template>
      </component>
    </transition>
    <!--/ Content -->
    <!--/ CONTENT -->

    <!-- Footer -->
    <footer class="footer">
      <slot name="footer">
        <app-footer />
      </slot>
    </footer>
    <slot name="customizer" />
  </div>
</template>

<script>
// import AppBreadcrumb from '@core/layouts/components/AppBreadcrumb.vue';
import AppNavbarHorizontalLayout from '@core/layouts/components/app-navbar/AppNavbarHorizontalLayout.vue';
import AppNavbarHorizontalLayoutBrand from '@core/layouts/components/app-navbar/AppNavbarHorizontalLayoutBrand.vue';
// import AppFooter from '@core/layouts/components/AppFooter.vue';
import useAppConfig from '@core/app-config/useAppConfig';
// import { BNavbar } from 'bootstrap-vue';
import { useScrollListener } from '@core/comp-functions/misc/event-listeners';

import { onUnmounted } from '@vue/composition-api';

// Content Renderer
// import LayoutContentRendererDefault from '@core/layouts/components/layout-content-renderer/LayoutContentRendererDefault.vue';
// import LayoutContentRendererLeft from '@core/layouts/components/layout-content-renderer/LayoutContentRendererLeft.vue';
// import LayoutContentRendererLeftDetached from '@core/layouts/components/layout-content-renderer/LayoutContentRendererLeftDetached.vue';

// Vertical Menu
/* eslint-disable import/order */
// import VerticalNavMenu from '@core/layouts/layout-vertical/components/vertical-nav-menu/VerticalNavMenu.vue';
import useVerticalLayout from '@core/layouts/layout-vertical/useVerticalLayout';
import mixinLayoutHorizontal from './mixinLayoutHorizontal';
import { menuItems } from '@/backoffice/constants/backofficeMenu';
import HorizontalNavMenu from './components/horizontal-nav-menu/HorizontalNavMenu.vue';
import useLayoutHorizontal from './useLayoutHorizontal';
import { myMenuItems, myselfMenuItems } from '@/views/myself/components/myMenu';
import GridStatusMixin from '@core/mixins/apps-layout/GridStatusMixin';
import fetchAppsInMenu from '@core/layouts/appsInMenu';

import { randomString } from '@/@core/utils/utils.js';

/* eslint-enable import/order */

export default {
  name: 'LayoutHorizontalCore',
  
  components: {
    // AppBreadcrumb,
    AppNavbarHorizontalLayout,
    AppNavbarHorizontalLayoutBrand,
    // AppFooter,
    HorizontalNavMenu,
    // BNavbar,
    // Content Renderer
    // LayoutContentRendererDefault,
    // LayoutContentRendererLeft,
    // LayoutContentRendererLeftDetached,
    // Vertical Menu
    // VerticalNavMenu,
    AppBreadcrumb: () => import("@core/layouts/components/AppBreadcrumb.vue" /* webpackChunkName: "AppBreadcrumb" */),
    AppFooter: () => import("@core/layouts/components/AppFooter.vue" /* webpackChunkName: "AppFooter" */),
    VerticalNavMenu: () => import('@core/layouts/layout-vertical/components/vertical-nav-menu/VerticalNavMenu.vue' /* webpackChunkName: "VerticalNavMenu" */),
    LayoutContentRendererDefault: () => import('@core/layouts/components/layout-content-renderer/LayoutContentRendererDefault.vue' /* webpackChunkName: "LayoutContentRendererDefault" */),
    LayoutContentRendererLeft: () => import('@core/layouts/components/layout-content-renderer/LayoutContentRendererLeft.vue' /* webpackChunkName: "LayoutContentRendererLeft" */),
    LayoutContentRendererLeftDetached: () => import('@core/layouts/components/layout-content-renderer/LayoutContentRendererLeftDetached.vue' /* webpackChunkName: "LayoutContentRendererLeftDetached" */),
  },
  mixins: [mixinLayoutHorizontal, GridStatusMixin],

  data() {
    return {
      appsInMenu: [],
      key: 'lkjlklkk',
      // keyForMenus: 'ksssklllk',
    };
  },
  computed: {
    // My Nectios Menu
    myItemsMenu() {
      return myMenuItems;
    },
    hasMyMenu() {
      return this.myItemsMenu.includes(this.$route.name);
    },
    apps() {
      return this.$store.getters.apps;
    },
    types() {
      return this.$store.getters.types;
    },
    myRoutesItems() {
      return [
        {
          title: this.$t('backoffice.back'),
          route: 'Nectios',
        },
        {
          title: this.$t('backoffice.dashboard.title'),
          route: 'my-dashboard',
        },
        {
          title: this.$t('backoffice.settings.title'),
          route: 'my-settings',
        },
        {
          title: this.$t('my-nectios.my-communities'),
          route: 'my-communities',
        },
        // {
        //   title: this.$t("backoffice.referrals.title"),
        //   route: 'my-referrals',
        // },
      ];
    },
    layoutContentRenderer() {
      const rendererType = this.$route.meta.contentRenderer;
      if (rendererType === 'sidebar-left') {
        return 'layout-content-renderer-left';
      }
      if (rendererType === 'sidebar-left-detached') {
        return 'layout-content-renderer-left-detached';
      }
      return 'layout-content-renderer-default';
    },
    layoutContentRendererKey() {
      return this.layoutContentRenderer === 'layout-content-renderer-left'
        ? this.$route.meta.navActiveLink || this.$route.name
        : null;
    },
    layoutContentRendererProps() {
      const { fitScreen } = this.$route.meta;

      if (this.layoutContentRenderer !== 'layout-content-renderer-default') {
        throw new Error('fitScreen only supported for default renderer');
      }

      const props = {};

      if (fitScreen) {
        props.fitScreen = true;
      }

      return props;
    },
    hasNotMenu() {
      return (
        this.$route.name === 'welcome'
        || this.$route.name === 'globalMemberSettings'
        || this.$route.name === 'myRoles'
        || this.$route.name === 'myConnections'
        || this.$route.name === 'myself-subcommunities'
        || this.$route.name === 'calendar'
        || this.$route.name === 'email'
        || this.$route.name === 'chats'
        || this.$route.name === 'onboarding-form'
        || this.$route.name === 'onboarding-plans'
        || this.$route.name === 'onboarding-checkout'
        || this.$route.name === 'onboarding-success'
        || this.$route.name === 'developers-center'
        || this.$route.name === 'forms-detail'
        || this.$route.name === 'forms-success'
      );
    },
    hasOnlyNavBar() {
      return (
        this.$route.name === 'forms-detail' || 
        this.$route.name === 'onboarding-form' || 
        this.$route.name === 'forms-success'
      );
    },
    hasBackofficeMenu() {
      return (
        menuItems.includes(this.$route.name)
      );
    },
    isMobile() {
      return this.isGridDisabled;
    },
    isStaff() {
      return this.$store.getters.loggedMemberRoles?.find(
        ({ tagName }) => tagName === 'staff' || tagName === 'owner',
      );
    },
    contentLayoutType() {
      if (this.isMobile) {
        return 'vertical';
      }
      if (this.$store.getters.currentCollective.menu?.layout) {
        return this.$store.getters.currentCollective.menu.layout;
      }
      return this.$store.state.appConfig.layout.type;
    },
    hasMyselfMenu() {
      return myselfMenuItems.includes(this.$route.name);
    },
    memberSettingsMenuItems() {
      return [
        {
          title: this.$t('backoffice.settings.title'),
          route: 'globalMemberSettings',
        },
        { 
          title: this.$t('backoffice.roles.my-roles'), 
          route: 'myRoles' 
        },
        {
          title: this.$t('settings.my-communities.title'),
          route: 'myself-subcommunities',
        },
        { 
          title: this.$t('members.member-detail.my-connections'), 
          route: 'myConnections' 
        },
        { 
          title: this.$t('calendar.title'), 
          route: 'calendar' 
        },
        { 
          title: this.$t('messages.title'), 
          route: 'email' 
        },
        // { 
        //   title: this.$t('chat.title'), 
        //   route: 'chats' 
        // },
        {
          title: this.$t('tickets.title'),
          route: 'orders',
        },
        {
          title: this.$t('backoffice.referrals.title'),
          route: 'myself-referrals',
        },
        { 
          title: this.$t('backoffice.passport'), 
          route: 'passport' 
        },
        {
          title: this.$t('backoffice.notifications.title'),
          route: 'myself-notifications',
        },
        // { 
        //   title: this.$t('tickets.title'), 
        //   route: '' 
        // },
      ];
    },
    boItems() {
      return [
        //Dashboard
        { 
          title: this.$t('backoffice.dashboard.title'), 
          route: 'backoffice' 
        },
        //Configuration
        {
          header: this.$t('backoffice-menu.config'),

          children: [
            {
              title: this.$t('backoffice.settings.title'),
              route: 'settings',
              icon: ' ',
            },
            {
              title: this.$t('backoffice.subscriptions.title'),
              route: 'plans',
              icon: ' ',
            },
            {
              title: this.$t('backoffice.discounts.title'),
              route: 'discounts-bo',
              icon: ' ',
            },
            {
              title: this.$t('backoffice.onboarding-form.title'),
              route: 'backoffice-onboarding-form',
              icon: ' ',
            },
            {
              title: this.$t('backoffice.templates.title'),
              route: 'templates-bo',
              icon: ' ',
            },
            {
              title: this.$t('backoffice.legal.title'),
              route: 'backoffice-legal',
              icon: ' ',
            },
          ],
        },
        // Apps
        { 
          title: 'App Store', 
          route: 'appstore' 
        },
        
        {
          header: this.$t('backoffice-menu.users'),

          children: [
            {
              title: this.$t('members.page-title.members-list'),
              route: 'backoffice-members',
              icon: ' ',
            },
            {
              title: this.$t('backoffice.roles.title'),
              route: 'roles',
              icon: ' ',
            },
            {
              title: this.$t('backoffice.referrals.title'),
              route: 'backoffice-referrals',
              icon: ' ',
            },
          ],
        },
        {
          header: this.$t('backoffice-menu.filters'),
          children: [
            {
              title: this.$t('backoffice.types.title'),
              route: 'types',
              icon: ' ',
            },
            {
              title: this.$t('backoffice.classifiers.name'),
              route: 'classifiers',
              icon: ' ',
            },
            {
              title: this.$t('backoffice.custom-fields.name'),
              route: 'custom-fields',
              icon: ' ',
            },
          ],
        },
        { 
          title: this.$t('backoffice.analytics.title'), 
          route: 'analytics' 
        },
        { 
          title: this.$t('backoffice.feedback.title'), 
          route: 'feedback' 
        },
      ];
    },
    backofficeItems() {
      //Section
      if (this.currentCollective.isSection) {
        return [
          { 
            title: this.$t('backoffice.dashboard.title'), 
            route: 'backoffice' 
          },
          {
            header: this.$t('backoffice-menu.config'),
            children: [
              {
                title: this.$t('backoffice.settings.title'),
                route: 'settings',
                icon: ' ',
              },
              {
                title: this.$t('backoffice.templates.title'),
                route: 'templates-bo',
                icon: ' ',
              },
            ],
          },
          { 
            title: 'App Store', 
            route: 'appstore' 
          },
          {
            header: this.$t('backoffice-menu.filters'),
            children: [
              {
                title: this.$t('backoffice.types.title'),
                route: 'types',
                icon: ' ',
              },
              {
                title: this.$t('backoffice.classifiers.name'),
                route: 'classifiers',
                icon: ' ',
              },
              {
                title: this.$t('backoffice.custom-fields.name'),
                route: 'custom-fields',
                icon: ' ',
              },
            ],
          },
          {
            title: this.$t('backoffice.analytics.title'),
            route: 'analytics',
            icon: ' ',
          },
          {
            title: this.$t('backoffice.feedback.title'),
            route: 'feedback',
            icon: ' ',
          },
          {
            title: this.$t('backoffice.availability.title'),
            route: 'availability',
            icon: ' ',
          },
        ];
      }

      //Event or subspace:
      if (
        this.currentCollective.isEvent || this.currentCollective.parentKey !== null
      ) {
        const availability = [
          {
            title: this.$t('backoffice.availability.title'),
            route: 'availability',
            icon: ' ',
          },
        ];
        return [...this.boItems, ...availability];
      }

      // Main Space
      const menu = [
        {
          title: this.$t('backoffice.dashboard.title'),
          route: 'backoffice',
        },
        {
          header: this.$t('backoffice-menu.config'),
          children: [
            {
              title: this.$t('backoffice.settings.title'),
              route: 'settings',
              icon: ' ',
            },

            {
              title: this.$t('backoffice.owner.title'),
              route: 'owner',
              icon: ' ',
            },
            {
              title: this.$t('backoffice.discounts.title'),
              route: 'discounts-bo',
              icon: ' ',
            },
            {
              title: this.$t('backoffice.onboarding-form.title'),
              route: 'backoffice-onboarding-form',
              icon: ' ',
            },
            {
              title: this.$t('backoffice.templates.title'),
              route: 'templates-bo',
              icon: ' ',
            },
            {
              title: this.$t('backoffice.legal.title'),
              route: 'backoffice-legal',
              icon: ' ',
            },
          ],
        },
        { title: 'App Store', route: 'appstore' },
        {
          header: this.$t('backoffice-menu.users'),
          children: [
            {
              title: this.$t('members.page-title.members-list'),
              route: 'backoffice-members',
              icon: ' ',
            },
            {
              title: this.$t('backoffice.roles.title'),
              route: 'roles',
              icon: ' ',
            },
            {
              title: this.$t('backoffice.referrals.title'),
              route: 'backoffice-referrals',
              icon: ' ',
            },
          ],
        },
        {
          header: this.$t('backoffice-menu.filters'),
          children: [
            {
              title: this.$t('backoffice.types.title'),
              route: 'types',
              icon: ' ',
            },
            {
              title: this.$t('backoffice.classifiers.name'),
              route: 'classifiers',
              icon: ' ',
            },
            {
              title: this.$t('backoffice.custom-fields.name'),
              route: 'custom-fields',
              icon: ' ',
            },
          ],
        },

        { title: this.$t('backoffice.analytics.title'), route: 'analytics' },
        { title: this.$t('backoffice.feedback.title'), route: 'feedback' },
        {
          title: this.$t('backoffice.availability.title'),
          route: 'availability',
        },
        {
          title: this.$t('backoffice.dev-center.title'),
          route: 'developers-center',
        },
      ];

      //Coordination Center
      if (this.isCoordinationCenter) {
        menu.pop();
        menu.splice(6, 4);
        menu.splice(10, 2);
      }

      //Workspace
      if (this.currentCollective.mainType != 'Workspace') {
        menu.map((item) => {
          if (item?.header === this.$t('backoffice-menu.config')) {
            item.children.splice(1, 0, { title: this.$t('backoffice.subscriptions.title'), route: 'plans', icon: ' ' });
          }
        });
      }

      return menu;
    },
    currentCollective() {
      return this.$store.getters.currentCollective;
    },
    isCoordinationCenter() {
      return this.$route.params.communityId.includes('coordination-center');
    },
  },
  watch: {
    '$i18n.locale': async function (newVal, oldVal) {
      this.appsInMenu = await fetchAppsInMenu();
      // this.keyForMenus = randomString();
    },
    async types() {
      this.appsInMenu = await fetchAppsInMenu();
      // this.keyForMenus = randomString();
    },
    async apps() {
      this.appsInMenu = await fetchAppsInMenu();
      // this.keyForMenus = randomString();
    },
  },
  async mounted() {
    // This call will not be made if we are in my-netios
    this.$router.afterEach((to, from) => {

      if(to.fullPath.indexOf("?chat-user") > -1) {
        return;
      }
      const fromPathIndex = from.fullPath.indexOf('?');
      const toPathIndex = to.fullPath.indexOf('?');

      let fromPath = fromPathIndex > -1
        ? from.fullPath.substring(0, fromPathIndex)
        : from.fullPath;
      let toPath = toPathIndex > -1 ? to.fullPath.substring(0, toPathIndex) : to.fullPath;

      if (fromPath.indexOf('#') > -1) {
        fromPath = fromPath.substring(0, fromPath.indexOf('#'));
      }

      if (toPath.indexOf('#') > -1) {
        toPath = toPath.substring(0, toPath.indexOf('#'));
      }

      if (fromPath !== toPath && from.fullPath != null) {
        this.key = randomString();
      }
    });
    if (this.$route?.params?.communityId) {
      this.appsInMenu = await fetchAppsInMenu();
    }
  },
  setup() {
    const {
      skin, navbarType, footerType, routerTransition, isNavMenuHidden,
    } = useAppConfig();

    // Vertical Menu
    const {
      isVerticalMenuActive,
      toggleVerticalMenuActive,
      overlayClasses,
      resizeHandler,
    } = useVerticalLayout(navbarType, footerType);

    // Resize handler
    resizeHandler();
    window.addEventListener('resize', resizeHandler);
    onUnmounted(() => {
      window.removeEventListener('resize', resizeHandler);
    });

    const { navbarMenuTypeClass, layoutClasses, footerTypeClass } = useLayoutHorizontal(navbarType, footerType, isVerticalMenuActive);

    // Scroll Listener
    const { scrolledTo } = useScrollListener();

    return {
      // skin
      skin,

      // Layout
      layoutClasses,

      // Navbar
      navbarType,
      navbarMenuTypeClass,

      // Menu Hidden
      isNavMenuHidden,

      // Router Transition
      routerTransition,

      // Footer
      footerTypeClass,

      // Scroll Listeners
      scrolledTo,

      // Vertical Menu
      isVerticalMenuActive,
      toggleVerticalMenuActive,
      overlayClasses,
    };
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/themes/bordered-layout.scss";
</style>
